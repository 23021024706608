/*
*   @site           YBN Cordae: Maze Game
*   @function       Maze
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Import
//-----------------------------------------------
import * as THREE from 'three-full/builds/Three.es.js';



//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Maze extends THREE.Object3D {
  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(scene, map, type, config) {
    //Make a container for the maze
    super();

    //Config
    this.config = config;

    //Side flags
    this.sides = ['left' , 'right' , 'top' , 'bottom'];

    //Maze
    this.map = map;

    //Single Geometry
    //this.mazeGeometry = new THREE.Geometry();

    //Materials
    this.materialsWall = [
      this.config.materials.get('wall'),
      this.config.materials.get('wall'),
      this.config.materials.get('wallTop'), //TOP
      this.config.materials.get('wall'),
      this.config.materials.get('wall'),
      this.config.materials.get('wall')
    ];

    //Blocks / Objects
    this.objects = [];

    //Box
    this.walls = {
      width:2,
      height:1,
      thickness:0.5
    };

    //Offsets
    this.offsets = {
      x:-(this.map[0].length) / 2,
      y:0,
      z:-(this.map.length) / 2
    };

    //Geom and mate
    this.wallGeometry = new THREE.BoxGeometry( this.walls.width, this.walls.height, this.walls.thickness );
    this.patchGeometry = new THREE.BoxGeometry( this.walls.thickness, this.walls.height, this.walls.thickness );
    
    //Loop through the map
    for(let z = 0; z < this.map.length; z++) {
      for(let x = 0; x < this.map[z].length; x++) {
        this.createGridSquare(x, 0, z, this.map[z][x]);
      }
    }

    //Add the mesh as one object to speed up processing time
    if(this.mazeGeometry) {
      let mesh = new THREE.Mesh( this.mazeGeometry, this.materialsWall);
      mesh.castShadow = true;
      mesh.receiveShadow = true;
      this.add(mesh);
    }

    //Add to the scene
    scene.add(this);
  }


  //-----------------------------------------------
  // Create a grid square
  //-----------------------------------------------
  createGridSquare(x, y, z, data = {}) {
    //Walls
    let block = {};

    //Make a wall for each side
    for(let i = 0; i < this.sides.length; i++)  {
      if(data[this.sides[i]] === true) {
        block[this.sides[i]] = this.createWall((x + this.offsets.x) * this.walls.width, (y + this.offsets.y) * this.walls.height, (z + this.offsets.z) * this.walls.width, this.sides[i]);
      }
    }
    
    //Patch corners

    //LEFT WALL checks
    if(x > 0 && this.map[z][x].left) {
      if(this.map[z][x].top && !this.map[z][x - 1].top) {
        this.patch(x, 0, z, 'TL');
      }
      if(this.map[z][x].bottom && !this.map[z][x - 1].bottom) {
        this.patch(x, 0, z, 'BL');
      }
    }

    //RIGHT WALL
    if(x < this.map[z].length - 1 && this.map[z][x].right) {
      if(this.map[z][x].top && !this.map[z][x + 1].top) {
        this.patch(x, 0, z, 'TR');
      }
      if(this.map[z][x].bottom && !this.map[z][x + 1].bottom) {
        this.patch(x, 0, z, 'BR');
      } 
    }
  }


  //-----------------------------------------------
  // Create a wall
  //-----------------------------------------------
  createWall(x, y, z, side) {
    //Position and rotation defaults
    let position = {x:x, y:y, z:z};
    let rotation = {x:0, y:0, z:0};

    //LEFT/RIGHT
    if(side === 'right' || side === 'left') {
      //Adjust Z
      rotation.y += Math.PI / 2;
      position.z += this.walls.width / 2;
      position.x += (side === 'right') ? this.walls.width - (this.walls.thickness / 2) : this.walls.thickness / 2;
    }

    //TOP/BOTTOM
    if(side === 'top' || side === 'bottom') {
      //Rotate
      position.x += this.walls.width / 2;
      position.z += (side === 'bottom') ? this.walls.width - (this.walls.thickness / 2) : this.walls.thickness / 2;
    }
  

    //Return
    return this.addMesh(position, rotation, this.wallGeometry);
  }


  //-----------------------------------------------
  // Add mesh
  //-----------------------------------------------
  addMesh(pos, rotation, geometry) {

    

    //Make it
    let mesh = new THREE.Mesh( geometry, this.materialsWall);

    //Position and rotate
    mesh.position.x = pos.x;
    mesh.position.y = pos.y;
    mesh.position.z = pos.z;
    mesh.rotation.x = rotation.x;
    mesh.rotation.y = rotation.y;
    mesh.rotation.x = rotation.z;

    //Add
    let offsetX = (rotation.y === 0) ? geometry.parameters.width / 2 : geometry.parameters.depth / 2;
    let offsetY = (rotation.y === 0) ? geometry.parameters.depth / 2 : geometry.parameters.width / 2;

    //Convertint to TOP LEFT coords
    this.objects.push({
      mesh:mesh,
      type:'wall',
      x:pos.x - offsetX,
      y:pos.z - offsetY,
      width:(rotation.y === 0) ? geometry.parameters.width : geometry.parameters.depth,
      height:(rotation.y === 0) ? geometry.parameters.depth : geometry.parameters.width
    });

    if(this.mazeGeometry) {
      mesh.updateMatrix();
      this.mazeGeometry.merge(mesh.geometry, mesh.matrix);
    } else {
      //Not combining geometry
      mesh.castShadow = true;
      mesh.receiveShadow = true;
      this.add( mesh );
    }

    //Return
    return mesh;
  }


  //-----------------------------------------------
  // Patch a block
  //-----------------------------------------------
  patch(x, y, z, type = 'TL') {
    //Position and rotation defaults
    let position = {x:(x + this.offsets.x) * this.walls.width, y:(y + this.offsets.y) * this.walls.height, z:(z + this.offsets.z) * this.walls.width};
    let rotation = {x:0, y:0, z:0};

    //Offset
    if(type == 'TR' || type == 'TL') {
      position.z -= this.walls.thickness / 2;
      position.x += (type == 'TR') ? this.walls.width + this.walls.thickness / 2 : -this.walls.thickness / 2;
    } else if(type == 'BR' || type == 'BL') {
      position.z += this.walls.width + (this.walls.thickness /2);
      position.x += (type == 'BR') ? this.walls.width + this.walls.thickness / 2 : -this.walls.thickness / 2;
    }

    //Add the mesh
    this.addMesh(position, rotation, this.patchGeometry);
  }


  //-----------------------------------------------
  // Update
  //-----------------------------------------------
  update() {

    //this.rotation.x += 0.02;
    //this.rotation.y += 0.01;
    //this.rotation.z += 0.02;

  }

  /*

  POTENTIAL TEXTURING EQUATION
  // geometry
  var width = 20;  // width of plane in world units
  var height = 10; // height of plane in world units
  var size = 5;    // texture block size in world units

  var w = width / size;
  var h = height / size;
  var geometry = new THREE.PlaneGeometry( width, height, 1, 1 );

  var uvs = geometry.faceVertexUvs[ 0 ];
  uvs[ 0 ][ 0 ].set( 0, h );
  uvs[ 0 ][ 1 ].set( 0, 0 );
  uvs[ 0 ][ 2 ].set( w, h );
  uvs[ 1 ][ 0 ].set( 0, 0 );
  uvs[ 1 ][ 1 ].set( w, 0 );
  uvs[ 1 ][ 2 ].set( w, h );

  // texture
  var texture = THREE.ImageUtils.loadTexture( "..." );
  texture.wrapS = texture.wrapT = THREE.RepeatWrapping;*/
}




