/*
*   @site           YBN Cordae: Maze Game
*   @function       Constants
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/



//-----------------------------------------------
// Global
//-----------------------------------------------
export const DEPLOY_MODE = (document.location.href.indexOf('localhost') !== -1) ? 'locals' : 'live';
export const DEBUG_MODE = (DEPLOY_MODE === 'local') ? false : false;

//-----------------------------------------------
// Dictionary
//-----------------------------------------------
export const DICTIONARY = {
  //Character Select
  'an-index': 'Some text'
};


//-----------------------------------------------
// Colours
//-----------------------------------------------
export const COLOURS = {
  'pink':0xec008c,
  'red':0xe14130,
  'orange':0xed872a,
  'green':0x8bc06a,
  'blue':0x6e9efa,
  'yellow':0xe7a200
};

//-----------------------------------------------
// CRM
//-----------------------------------------------
export const CRM = {
  'newsletterID':14077777,
  'ext':'1C8E10B0-F680-49E6-9B11-47B8A203BE70:6424730',
  'dataSource':'YBN_Cordae_mazegame_registration',
  'endpoints':{
    'subscribe':'https://signup.wmg.com/register',
    'unsubscribe':'https://signup.wmg.com/unsubscribe'
  }
};


//-----------------------------------------------
// Shaders
//-----------------------------------------------
export const SHADERS = {

  'pixelate': {
    uniforms: {
      'tDiffuse': { value: null },
      'intensity': {value:1.0}
    },

    vertexShader: [
      'varying vec2 vUv;',
      'void main() {',
      'vUv = uv;',
      'gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',
      '}'

    ].join( '\n' ),

    fragmentShader: [
      'varying vec2 vUv;',
      'uniform sampler2D tDiffuse;',
      'uniform vec2 u_resolution;',
      'uniform float intensity;',
      'vec3 bg(vec2 uv) {',
      '   return texture2D(tDiffuse, uv).rgb;',
      '}',
      'vec3 effect(vec2 uv, vec3 col) {',
      '   float granularity = floor(intensity*20.+10.);',
      '   if(mod(granularity,2.) > 0.) {',
      '       granularity += 1.0;',
      '   };',
      '   if(granularity > 0.0) {',
      '       float dx = granularity / u_resolution.x;',
      '       float dy = granularity / u_resolution.y;',
      '       uv = vec2(dx*(floor(uv.x/dx) + 0.5),dy*(floor(uv.y/dy) + 0.5));',
      '       return bg(uv);',
      '   };',
      '   return col;',
      '}',
      'void main() {',
      'vec3 tex = bg(vUv);',
      'vec3 col = effect(vUv,tex);',
      'gl_FragColor = vec4( col, 1. );',
      '}'
    ].join( '\n' )
  }
};