/*
*   @site           Generic App
*   @function       App Index
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Import
//-----------------------------------------------

//Items
import Engine from '@/engine';
import Maze from '@/maze';
import Materials from '@/materials';
import Player from '@/player';
import Floor from '@/floor';
import Joystick from '@/joystick';
import Login from '@/login';

//Sound
import {Howl} from 'howler';

//CSS
import '@/../styles/app.scss';





//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class YNBCordaeMaze {
  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor() {
    //Safari Hack fix for Three JS
    window.ImageBitmap = window.ImageBitmap || function () { return null; };

    //Generate map
    let map = MazeGenerator(15, 15);

    //Login Mechanic
    this.login = new Login();

    //Engine
    this.engine = new Engine({pixelate:true, pixelIntensity:0.0, shadowEnabled:true, fov:60});

    //Materials
    let materials = this.materials = new Materials(this.engine);

    //Maze
    this.maze = new Maze(this.engine.scene, map, 'main', {materials:materials});
    
    //Floor
    this.floor = new Floor(this.engine.scene, this.maze, map, {materials:materials});

    //Player
    this.player = new Player(this.engine.scene, this.maze, map);

    //Track the player...
    this.engine.track(this.player); 

    //Joystick
    this.joystick = new Joystick();


    //Animate
    window.requestAnimationFrame( (timestamp) => this.animate(timestamp));

    //Music
    this.music = new Howl({
      src: ['audio/music.mp3'],
      autoplay: true,
      loop: true,
      volume: 0.25
    });

    //Controls
    this.keys = {
      down:{active:false, codes:[83,40]},
      up:{active:false, codes:[87, 38]},
      left:{active:false, codes:[65, 37]},
      right:{active:false, codes:[68,39]},
      camera:{active:false, codes:[32]}
    };
    document.addEventListener('keydown', (event) => this.keyEvent(event, true), false);
    document.addEventListener('keyup', (event) => this.keyEvent(event, false), false);

  }


  //-----------------------------------------------
  // Keys Down
  //-----------------------------------------------
  keyEvent(event, value) {
    for(let i in this.keys) {
      if(this.keys[i].codes.indexOf(event.which) !== -1) {
        this.keys[i].active = value;
        break;
      }
    }
  }


  //-----------------------------------------------
  // Animate
  //-----------------------------------------------
  animate(timestamp) {
    if(!this.last) {
      this.last = timestamp;
    }
    let diff = timestamp - this.last;
    this.last = timestamp;
    //Maze update
    this.maze.update();

    //Add joysticks on top
    if(this.joystick.active) {
      for(let i in this.joystick.directions) {
        this.keys[i].active = this.joystick.directions[i];
      }
    }

    //Player
    this.player.update(this.keys, diff);

    //Engine
    this.engine.update(this.keys);

    //Go again
    window.requestAnimationFrame( (timestamp) => this.animate(timestamp));
  }
}



//-----------------------------------------------
// Boot
//-----------------------------------------------
new YNBCordaeMaze({});