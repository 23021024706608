/*
*   @site           YBN Cordae: Maze Game
*   @function       Floor
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Import
//-----------------------------------------------
import * as THREE from 'three-full/builds/Three.es.js';


//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Floor extends THREE.Object3D {
  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(scene, maze, map, config) {
    //Make a container for the maze
    super();

    //Vars
    let floorOverage = 1;

    //Plane
    let planeGeometry = new THREE.PlaneGeometry( maze.walls.width * (map[0].length + floorOverage), maze.walls.width * (map.length + floorOverage), 32 );
    let plane = new THREE.Mesh( planeGeometry, config.materials.get('floor') );
    plane.rotation.x = -Math.PI / 2;
    plane.position.y = -maze.walls.height / 2;
    plane.receiveShadow = true;

    //Add it
    this.add(plane);
    scene.add(this);
  }
}