/*
*   @site           YBN Cordae: Maze Game
*   @function       Player
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Import
//-----------------------------------------------
import * as THREE from 'three-full/builds/Three.es.js';
import { TweenMax, Power1 } from 'gsap/TweenMax';
import {Howl} from 'howler';


//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Player extends THREE.Object3D {
  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(scene, maze, map) {
    //Make a container for the maze
    super();

    //Footsteps
    this.stepLast = -1;
    this.steps = [];
    for(let i = 1; i <= 18; i++) {
      this.steps.push(
        new Howl({
          src: ['audio/step' + i + '.mp3'],
          autoplay: false,
          loop: false,
          volume: 0.4
          /*,
          onend: function() {
            console.log('Finished!');
          }*/
        })
      );
    }

    //Refs
    this.maze = maze;
    this.map = map;

    //Speed
    this.speedConstant = 0.012;
    this.speedWalk = 0.02;
    this.speedTurn = (Math.PI / 180) * 0.75;

    //Facing
    if(!map[0][0].bottom) {
      //DOWN
      this.rotation.y = Math.PI;
    } else {
      //RIGHT
      this.rotation.y = Math.PI / 2;
    }

    //Quarter of a wall width radius
    this.radius = maze.walls.width * 0.1;
    var geometry = new THREE.SphereGeometry( this.radius, 8, 8 );
    var material = new THREE.MeshBasicMaterial( {color: 0xffff00} );
    var sphere = new THREE.Mesh( geometry, material );
    this.add( sphere );

    //Base Pos
    this.baseY = maze.offsets.y  * maze.walls.height;

    //Position set
    this.position.x = (maze.offsets.x + 0.5) * maze.walls.width;
    this.position.y = this.baseY;
    this.position.z = (maze.offsets.z + 0.5) * maze.walls.width;

    //Walk
    this.bob = {height:0.03, speed:0.4 / (this.speedWalk / this.speedConstant)};
    this.walking = false;

    //Move Vector
    this.vector = {x:0, y:0};
    
    //Add to the scene
    scene.add(this);
  }


  //-----------------------------------------------
  // Walk repeat
  //-----------------------------------------------
  walkRepeat() {
    if(this.position.y <= (this.baseY) + (this.bob.height * 0.5)) {
      this.step();
    }
  }


  //-----------------------------------------------
  // Update
  //-----------------------------------------------
  update(keys, diff) {
    //Vector
    this.vector = {x:0, y:0};

    //LEFT / RIGHT
    if(keys.left.active) {
      this.vector.x = -1;
    } else if(keys.right.active) {
      this.vector.x = 1;
    } 

    //FORWARD / BACK
    if(keys.up.active) {
      this.vector.y = 1;
    } else if(keys.down.active) {
      this.vector.y = -0.5;
    }

    //Angle

    //Walking
    if(this.vector.y === 0 && this.walking) {
      this.walk(false);
    } else if(this.vector.y != 0 && !this.walking) {
      this.walk(true);
    }


    //Turn (always allowed)
    this.rotation.y += this.vector.x * this.speedTurn * (diff / 16);
    let angle = this.rotation.y - (Math.PI / 2);

    //Movement
    let newX = this.position.x + (Math.cos(angle) * this.speedWalk * this.vector.y * (diff / 16));
    let newY = this.position.z + (Math.sin(angle) * this.speedWalk * this.vector.y * (diff / 16));
    if(!this.movePosition(newX, newY)) {
      if(!this.movePosition(this.position.x, newY)) {
        this.movePosition(newX, this.position.z);
      }
    }
  }


  movePosition(x, y) {
    //Bounds
    let bounds = {
      left:x - this.radius,
      right:x + this.radius,
      top:y - this.radius,
      bottom:y + this.radius
    };

    let collides = {};
    let canMove = true;

    //Loop
    for(let i = 0; i < this.maze.objects.length; i++) {
      collides.left = (bounds.left >= this.maze.objects[i].x && (bounds.left <= (this.maze.objects[i].x + this.maze.objects[i].width))) ? true : false;
      collides.right = (bounds.right >= this.maze.objects[i].x && (bounds.right <= (this.maze.objects[i].x + this.maze.objects[i].width))) ? true : false;
      collides.top = (bounds.top >= this.maze.objects[i].y && (bounds.top <= (this.maze.objects[i].y + this.maze.objects[i].height))) ? true : false;
      collides.bottom = (bounds.bottom >= this.maze.objects[i].y && (bounds.bottom <= (this.maze.objects[i].y + this.maze.objects[i].height))) ? true : false;

      //Fail?
      if((collides.left || collides.right) && (collides.top || collides.bottom)) {
        canMove = false;
        break;
      }
      
    }

    //Set
    if(canMove) {
      //Set
      this.position.x = x;
      this.position.z = y;
      return true;
    } else {
      return false;
    }
  }


  //-----------------------------------------------
  // Walk
  //-----------------------------------------------
  walk(action) {

    //Stop tweens
    TweenMax.killTweensOf(this.position);

    //Act
    if(action) {
      //Start
      TweenMax.to(this.position, this.bob.speed, {y:this.baseY + this.bob.height, repeat:-1, yoyo:true, repeatDelay:0, ease:Power1.easeInOut, onRepeat:function() { this.walkRepeat(); }.bind(this)}); //, 
      this.step();
    } else {
      //Stop
      TweenMax.to(this.position, this.bob.speed / 2, {y:this.baseY , repeat:0, ease:Power1.easeInOut });
    }

    //Flag
    this.walking = action;
  }


  //-----------------------------------------------
  // Sound
  //-----------------------------------------------
  step() {
    //Pick a sound
    let id = Math.floor(Math.random() * this.steps.length);
    while(id === this.stepLast) {
      id = Math.floor(Math.random() * this.steps.length);
    }
    this.stepLast = id;
    this.steps[id].stop();
    this.steps[id].play();
  }
}
