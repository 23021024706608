/*
*   @site           YBN Cordae: Maze Game
*   @function       Materials / Textures
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Import
//-----------------------------------------------
import * as THREE from 'three-full/builds/Three.es.js';



//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Materials {
  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(/**/) {
    //List
    this.textures = {};
    this.materials = {}; 

    //Walls and Floors
    this.addStandardMaterial('wall', 'images/wall1.png', {}, {wrapping:[2, 1]});
    this.addStandardMaterial('floor', 'images/floor.png', {}, {wrapping:[128, 128]});

    //Sim
    this.materials['wallTop'] = new THREE.MeshLambertMaterial({color:0x434343}); //good for non-shiny
  }


  //-----------------------------------------------
  // Add 'standard' material
  //-----------------------------------------------
  addStandardMaterial(id, path, materialConfig = {}, textureConfig = {}) { //, 
    //Loader
    let loader = new THREE.TextureLoader();
    this.textures[id] = {
      'item':loader.load( path, function (id) {
        this.textureLoaded(id);
      }.bind(this, id)),
      config:textureConfig
    };
    
    //Set the map
    materialConfig.map = this.textures[id].item;

    //Material
    this.materials[id] = new THREE.MeshStandardMaterial(materialConfig);
  }


  //-----------------------------------------------
  // Texture load complete
  //-----------------------------------------------
  textureLoaded(id) {
    //Setup texture now it's loaded
    if(this.textures[id].config.wrapping) {
      this.textures[id].item.wrapS = this.textures[id].item.wrapT = THREE.RepeatWrapping;
      this.textures[id].item.repeat.set( this.textures[id].config.wrapping[0], this.textures[id].config.wrapping[1] );
    }

    this.textures[id].item.magFilter = THREE.NearestFilter;

    //Wrapping after load
    //texture.offset.set( 0, 0 );
    //texture.repeat.set( 4, 1 );
  }


  //-----------------------------------------------
  // Get a material
  //-----------------------------------------------
  get(id, type = 'material') {
    return this[type + 's'][id];
  }
  
}