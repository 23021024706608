/*
*   @site           YBN Cordae: Maze Game
*   @function       Login Management
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Import
//-----------------------------------------------
import Helper from '@/utilities/helper';


//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Login {
  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor() {
    //Topsify Button
    this.connectButton = new window.WMGConnect('.wmg-button', {
      exacttarget_datasource: Helper.constants.CRM.dataSource,
      opt_in_thank_you_enabled: false,
      opt_in_dialogue_enabled: false
    });

    //Callback
    this.connectButton.setCallback(function(data) {
      console.log(data);
    }.bind(this));


    //Try a couple of things
    //this.competitionComplete('greg.findon@gmail.com');
    //this.register('Greg Findon', 'gregfindon@gmail.com', 'SW198LU', 'GB');
    //this.competitionComplete('gregfindon@gmail.com');
    //this.unsubscribe('gregfindon@gmail.com');
    //this.unsubscribe('greg.findon@gmail.com');
    //this.unsubscribe('greg.findon@playnicely.com');
    

    //Response callback
    window.crmResponseCatcher = function(data) {
      this.crmResponse(data);
    }.bind(this);
  }


  //-----------------------------------------------
  // Competition Complete
  //-----------------------------------------------
  competitionComplete(email) {
    //Build our data
    let data =  {
      email:email,
      contest_entry:true,
      time_entered_contest:'05/24/2019'
    };

    //
    this.crmSend(data, 'enter');
  }


  //-----------------------------------------------
  // Register
  //-----------------------------------------------
  register(name, email, postcode, country = 'US', optin = true) {
    //Data
    let data =  {
      email:email,
      firstname:name,
      postcode:postcode,
      country:country,
      global_optin:optin //true/false from checkbox
    };

    //Send it
    this.crmSend(data, 'subscribe');
  }


  //-----------------------------------------------
  // Unsubscribe
  //-----------------------------------------------
  unsubscribe(email) {
    this.crmSend({email:email}, 'unsubscribe', 'unsubscribe');
  }


  //-----------------------------------------------
  // CRM Send
  //-----------------------------------------------
  crmSend(data, id, endpointID = 'suscribe') {
    if(Helper.constants.CRM.endpoints[endpointID]) {
      //Constants
      let dataSend = {
        newsletterID:Helper.constants.CRM.newsletterID,
        _ext: Helper.constants.CRM.ext,
        datasource:Helper.constants.CRM.dataSource,
        ref_url:document.location.origin,
        jsonp:'crmResponseCatcher',
      };

      //Store the callback ID
      this.callbackID = id;

      //GEO IP Population?
      //geoip=true
      //ip_address={user’s ip}

      //Merge new data in
      for(let i in data) {
        dataSend[i] = data[i];
      }

      //Send
      console.log(dataSend);
      $.ajax({
        type: 'POST',
        url: Helper.constants.CRM.endpoints[endpointID],
        data: dataSend,
        dataType: 'jsonp'
      });
    }
  }


  //-----------------------------------------------
  // CRM Response
  //-----------------------------------------------
  crmResponse(data) {
    console.log(data, this.callbackID);
  }
}





/*
service: "spotify"
user:
campaign: "19012"
country: "GB"
date_of_birth: "0000-00-00"
date_time_added: "2019-05-22 10:40:07"
email: "greg.findon@last17.com"
id: "13743492"
ip_address: "81.102.113.222"
ip_city: "Leicester"
ip_continent: "EU"
ip_country: "GB"
ip_region: "H4"
name: "Mixibits"
spotify_access_token: "BQBV9v-Ao6pk-67QsQDR5PqcfaE9u-3LU3-be11SPUHhnz9ng3QnVqlagBkNcv5jdRzkJ7-fYFwNxZvmZBNJ1hfUSylTkjHgD4uHCZuTkYfrIXSedP4SLSavq177l_WC1Lr18ljwNAaR2M1wSH9ntDCT8eZga1TD3a31iatuUI01-AQEYYpRvAR-n8DXQTTVQ40M48aJPG6PKZNray92svLS20NNtvlpip6Wa2GPceFhk4zMHXddkCrBdCGNKSHXQj-kav--vhoF5nI"
spotify_refresh_token: "AQAfMj13GZNKDOUTV__1fFI84hfN7-HSryfuRW0cHJvLs1CyEvkWLFKi64-hyNnE2p3IYJ1jQnxvRSRZW-Iurjvi2ormxtujBIH3hMIk9UI9kE8B8Rb0a7bJ0eu9rqpuX1Z-Xw"
uri: "spotify:user:ycrvzef5gakoigw4p2fjtmhax"
    */