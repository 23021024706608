/*
*   @site           YBN Cordae: Maze Game
*   @function       Engine
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Import
//-----------------------------------------------
import * as THREE from 'three-full/builds/Three.es.js';
import Helper from '@/utilities/helper';
import { TweenMax, Power1 } from 'gsap/TweenMax';



//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Engine {
  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(config) {
    //Config
    this.config = config;

    //Vars
    let NEAR = 0.1, FAR = 1000;
    let SCREEN_WIDTH = window.innerWidth;
    let SCREEN_HEIGHT = window.innerHeight;

    //Flags
    this.flags = {
      tracking:false,
      canSwitchCamera:true,
      tweening:false
    };
  
    //Camera    
    let camera = this.camera = new THREE.PerspectiveCamera( config.fov, SCREEN_WIDTH / SCREEN_HEIGHT, NEAR, FAR );
    camera.position.y = 40;
    camera.rotation.x = -Math.PI / 2;

    //Trackable object
    this.trackObject = null;

    //Scene
    let scene = this.scene = new THREE.Scene();
    scene.background = new THREE.Color( 0x88e8de );

    //Renderer
    let renderer = this.renderer = new THREE.WebGLRenderer( { antialias: false } );
    renderer.setPixelRatio( Math.max(window.devicePixelRatio, 2) );
    renderer.shadowMap.enabled = this.config.shadowEnabled;
    //renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap

    //Post Process
    let composer = new THREE.EffectComposer( renderer );
    composer.addPass( new THREE.RenderPass( scene, camera ) );
    let effect = new THREE.ShaderPass( Helper.constants.SHADERS.pixelate );
    effect.uniforms[ 'intensity' ].value = this.config.pixelIntensity;
    effect.uniforms['u_resolution'] = {
      type: 'v2', value: new THREE.Vector2()
    };
    effect.renderToScreen = true;
    composer.addPass( effect );
    this.postprocessing = {
      composer:composer,
      effect:effect
    };


    //Add to body
    document.body.appendChild( this.renderer.domElement );

    //Lights
    this.initLights();

    //Resize
    window.addEventListener( 'resize', function() { this.onWindowResize(); }.bind(this), false );
    this.onWindowResize();
  }


  //-----------------------------------------------
  // Lights
  //-----------------------------------------------
  initLights() {
    //Create a PointLight and turn on shadows for the light
    this.light1 = new THREE.PointLight( 0xABABAB, 0.8, 100); //intensity, distance, decay
    this.light1.position.set( 0, 10, 0 );
    this.light1.castShadow = true;    


    //Set up shadow properties for the light
    this.light1.shadow.mapSize.width = 2048; 
    this.light1.shadow.mapSize.height = 2048; 
    this.light1.shadow.camera.near = 1;       
    this.light1.shadow.camera.far = 100;      
    this.light1.shadow.radius = 0;

    //Sun
    this.sun = new THREE.DirectionalLight(0xffffff, 0.6);
    this.scene.add(this.sun);

    //Add it
    this.scene.add( this.light1 );

    //Ambient light
    let ambient = new THREE.AmbientLight(0xCCCCCC, 1);
    this.scene.add(ambient);

    //Light helper
    /*var helper = new THREE.CameraHelper( this.light1.shadow.camera );
    this.scene.add( helper );*/
  }


  //-----------------------------------------------
  // Resize
  //-----------------------------------------------
  onWindowResize() {
    //Size
    let SCREEN_WIDTH = window.innerWidth;
    let SCREEN_HEIGHT = window.innerHeight;

    //Camera
    this.camera.aspect = SCREEN_WIDTH / SCREEN_HEIGHT;
    this.camera.updateProjectionMatrix();

    //Renderer
    this.renderer.setSize( SCREEN_WIDTH, SCREEN_HEIGHT );

    //Pixel filter
    this.postprocessing.composer.setSize( SCREEN_WIDTH, SCREEN_HEIGHT );
    this.postprocessing.effect.uniforms['u_resolution'].value.x = this.renderer.domElement.width;
    this.postprocessing.effect.uniforms['u_resolution'].value.y = this.renderer.domElement.height;
  }

  //-----------------------------------------------
  // Track
  //-----------------------------------------------
  track(target, on = true) {
    if(on) {
      //Object
      this.trackObject = target;

      //Flags
      this.flags.tracking = true;

      //Fog!
      this.scene.fog = new THREE.Fog( 0x000000, 1, 10 );
    }
  }

 

  //-----------------------------------------------
  // Switch mode
  //-----------------------------------------------
  switchMode() {
    //Kill tweens
    TweenMax.killTweensOf(this.camera.position);
    TweenMax.killTweensOf(this.camera.rotation);
    TweenMax.killTweensOf(this.scene.fog);


    //Switch mo
    if(this.flags.tracking) {
      //Fog off, tracking off
      this.flags.tracking = false;

      //Move
      TweenMax.to(this.camera.position, 2, {delay:0, x:0, y:40, z:0, ease:Power1.easeInOut, onComplete:function() { this.tweenComplete(false); }.bind(this)});
      TweenMax.to(this.camera.rotation, 2, {x:-Math.PI / 2, y:0, z:0, ease:Power1.easeInOut});
      TweenMax.to(this.scene.fog, 2, {far:100, ease:Power1.easeInOut});

    
    } else {
      //Move
      TweenMax.to(this.camera.position, 1.5, {x:this.trackObject.position.x, y:this.trackObject.position.y - 0.15, z:this.trackObject.position.z, ease:Power1.easeInOut, onComplete:function() { this.tweenComplete(true); }.bind(this)}); //, 
      TweenMax.to(this.camera.rotation, 1.5, {x:this.trackObject.rotation.x, y:-this.trackObject.rotation.y, z:this.trackObject.rotation.z, ease:Power1.easeInOut}); //, 
      TweenMax.to(this.scene.fog, 1.5, {far:10, ease:Power1.easeInOut});
    }

    //Flag off
    this.flags.tweening = true;
    this.flags.canSwitchCamera = false;
  }


  tweenComplete(action) {
    this.flags.tracking = action;
    this.flags.tweening = false;
  }


  //-----------------------------------------------
  // Render
  //-----------------------------------------------
  update(keys) {
    if(keys.camera.active && this.flags.canSwitchCamera && !this.flags.tweening) {
      //Switch mode
      this.switchMode();
    } else if(!keys.camera.active && !this.flags.canSwitchCamera) {
      //Reset
      this.flags.canSwitchCamera = true;
    }

    //Move the camera to match the player
    if(this.trackObject && this.flags.tracking) {
      //Track an object
      this.camera.position.x = this.trackObject.position.x;
      this.camera.position.y = this.trackObject.position.y - 0.15;
      this.camera.position.z = this.trackObject.position.z;

      //Rotation
      this.camera.rotation.x = this.trackObject.rotation.x;
      this.camera.rotation.y = -this.trackObject.rotation.y;
      this.camera.rotation.z = this.trackObject.rotation.z;
    }

    //Render
    if(this.config.pixelate) {
      this.postprocessing.composer.render( this.scene, this.camera );
    } else {
      this.renderer.render( this.scene, this.camera );
    }
  }


  
}