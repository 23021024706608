/*
*   @site           YBN Cordae: Maze Game
*   @function       Engine
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Import
//-----------------------------------------------
import nipplejs from 'nipplejs';


//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Joystick {
  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor() {
    //Options
    let options = {
      zone: document.getElementById('joystick')
    };

    //Directions
    this.directions = {
      left:false,
      right:false,
      up:false,
      down:false
    };

    //Offset
    this.offsets = {
      move:20,
      up:25
    };

    //Flag
    this.active = false;

    //Manager
    this.manager = nipplejs.create(options);

    //Listen to events
    this.manager.on('added', function (evt, nipple) { this.added(evt, nipple); }.bind(this));
    this.manager.on('removed', function (evt, nipple) { this.removed(evt, nipple); }.bind(this));

  }


  //-----------------------------------------------
  // Added one
  //-----------------------------------------------
  added(event, joystick) {
    console.log('new');
    //Listen to events
    joystick.on('start move end dir plain', function (evt, data) {
      this.event(evt, data);
    }.bind(this));

    this.active = true;
  }


  //-----------------------------------------------
  // Event
  //-----------------------------------------------
  event(evt, data = null) {
    if(data && data.angle && data.distance && data.distance > this.offsets.move) {
      //console.log(data.angle.degree);
      this.directions.up = (data.angle.degree >= this.offsets.up && data.angle.degree <= (180 - this.offsets.up)) ? true : false;
      this.directions.left = (data.angle.degree >= 100 && data.angle.degree <= 210) ? true : false;
      this.directions.right = ((data.angle.degree >= 0 && data.angle.degree <= 80) || (data.angle.degree >= 330 && data.angle.degree <= 360)) ? true : false;


    } else {
      //Reset
      this.directions = { left:false, right:false, up:false, down:false};
    }
  }


  //-----------------------------------------------
  // Removed one
  //-----------------------------------------------
  removed(event, joystick) {
    joystick.off('start move end dir plain');
    this.active = false;
  }
}


    

/*/ nipple.on('start move end dir plain', function (evt) {
        // DO EVERYTHING
    });*/


/*this.joystick.on('added', function (evt, nipple) {
  nipple.on('start move end dir plain', function (evt) {
    console.log(evt);
    //this.joystickEvent(evt);
  });
}).on('removed', function (evt, nipple) {
  nipple.off('start move end dir plain');
});*/